

import Vue from "vue"
import { ClientInvitation, ClientInvitationRegistration, SnackbarInfo, ChangeActiveRequest, ChangeManualApprovalRequest} from "@/types/responses"
import { DataTableHeader } from "vuetify"

const ApproveRegistrationDialog = () => import("@/components/dialogs/ApproveRegistrationDialog.vue")

export default Vue.extend({
    name: "ManageInvitationDialog",
	components: {
		ApproveRegistrationDialog
	},
	props: {
		manageInvitation: {
			type: Boolean,
		}
	},
    async mounted() {
        await this.getClientInvitationRegistrations();
	},
	data(): {
		search: string
		showApprove: boolean
		loading: boolean
		indicativeLoading: boolean
		mandatory: boolean
		activeClass: string
		selectedChip: string
        headers: DataTableHeader[]
        timeLeft: number
		snackTimeout: number

		snackBar: boolean
		snackBarText: string

		selectedRegistration: ClientInvitationRegistration | null
	} {
		return {
			search: "",
			showApprove: false,
			loading: false,
			indicativeLoading: false,
			mandatory: false,
			activeClass: "",
			selectedChip: "",
            headers: [
				{ text: "Name", value: "displayName", sortable: false },
				{ text: "Email", value: "email", sortable: false },
				{ text: "Organization", value: "clientOrganizationName", sortable: false, filterable: false },
				{ text: "Verified with BankID", value: "verifiedWithBankId", align: 'center', sortable: false, filterable: false },
				{ text: "Status", value: "status", align: 'center', sortable: true, filterable: false },

				{ text: "", value: "action", align: 'center', sortable: false, filterable: false },
			],
            timeLeft: 2,
			snackTimeout: 1,

			snackBar: false,
			snackBarText: "",

			selectedRegistration: null
		}
	},
    watch: {
		clientInvitationRegistrations: {
            deep: true,
            handler(){
                console.log("updated clientInvitationRegistrations()")

                /* Error handler: wrong data loaded
                if(
                    this.clientInvitationInfo!.clientInvitationId !== 
                    this.clientInvitation!.id
                ){
                    this.getClientInvitationRegistrations()
                }*/
            }
        }
	},
    computed: {
        clientInvitation(): ClientInvitation {
            return this.$store.state.context!.selectedClientInvitation 
        },
		clientInvitationRegistrations(): ClientInvitationRegistration[] {
            if(!this.loading) return this.$store.state.context!.selectedClientInvitationRegistrations
            return []
        },
	},
	methods: {
		async countDownTimer(text: string, showCountdown: boolean, length: number): Promise<void> {
			this.timeLeft = length
			if(showCountdown) this.snackBarText = text + `<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
			else this.snackBarText = text

			return new Promise((resolve) => {
				const downloadTimer = setInterval(() => {
					this.timeLeft--
					if(showCountdown) this.snackBarText = text + `<br/><br/>Finalizing in ${this.timeLeft} seconds.. `
					else this.snackBarText = text
					
					if (this.timeLeft <= 0) {
						clearInterval(downloadTimer)
						resolve()
					}
				}, 1000)
			})
		},
        async getClientInvitationRegistrations(): Promise<void> {
			if (this.loading) return
			this.loading = true
            try {
                await this.$vStore.dispatch("context/getClientInvitationRegistrations")
                this.loading = false;
            } catch(e){
                console.warn("Error: getClientInvitationRegistrations()")
                this.loading = false;
            }
		},
		async approveRegistrationClick(item: ClientInvitationRegistration): Promise<void> {
			// Double check validity of client registration
			const hit = this.$store.state.context.selectedClientInvitationRegistrations.find(
				(clientRegistration: ClientInvitationRegistration) => clientRegistration.jobId === item.jobId
			)
			if(hit){
				/* Store selection */
            	await this.$vStore.dispatch("context/setRegistration", hit)
				this.selectedRegistration = hit;
            	this.openApproveDialog()
			} else {
				this.selectedRegistration = null;
				console.warn("Error selecting client registration.")
			}
		},
		async silentRefresh(): Promise<void> {
			this.indicativeLoading = true
		
			try {
				await this.countDownTimer("", true, 1)
				await this.$vStore.dispatch("context/getClientInvitationRegistrations")
			} catch (e) {
				console.warn("silentRefresh failed.", e)
			}

			this.indicativeLoading = false
		},
		openApproveDialog(): void {
			this.showApprove = true
		},
		cancel(): void {
			this.$emit("close", null)
		},

		translateStatus(status: string): string {
			if (status == "EmailSent") {
				return "Ready";
			}
			if (status == "Completed") {
				return "Logged in";
			}

			return status;
		},
		
		async showSnackbarInfo(showLoading: boolean, snackbarInfo: SnackbarInfo, showCountdown: boolean, length: number): Promise<void> {			
			this.snackTimeout = length * 1000
			this.snackBar = true

			if(snackbarInfo.success){
				if(showLoading) this.loading = true
				try{
					await this.countDownTimer(snackbarInfo.msg, showCountdown, length)
				} catch (e){}
				this.loading = false
			} else {
				if(showCountdown) await this.countDownTimer(snackbarInfo.msg + "<br/><br/>Error: " + JSON.stringify(snackbarInfo.e), showCountdown, length)
				else this.snackBarText = snackbarInfo.msg + "<br/><br/>Error: " + JSON.stringify(snackbarInfo.e)
			}
		}
	},
})
